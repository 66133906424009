$color: #3964f9;
#list {
  .banner {
    .zz {
      background-color: rgba(0,0,0,.5);
    }
  }
  .form-line-box {
    display: flex;
    padding: 30px 35px 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: white;
    margin-top: 30px;
    .left {
      flex: 1;
      .line {
        display: flex;
        width: 100%;
        .ant-form-item-label {
          width: 90px;
        }
        &:first-child {
          >.input-item {
            flex: 1;
            margin-right: 30px;
          }
          div:last-child {
            .ant-form-item-label {
              width: 100px;
            }
          }
        }
        &:last-child {
          .ant-form-item {
            width: 320px;
            &:not(:last-child) {
              margin-right: 30px;
            }
          }
        }
      }
    }
    >button {
      margin-left: 40px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: flex-start;
    >.left {
      background-color: white;
      border: 1px solid #eee;
      flex: 1;
      margin-right: 15px;
      border-radius: 5px;
      >.title {
        font-weight: 700;
        line-height: 40px;
        padding-left: 15px;
        border-bottom: 1px solid #eee;
      }
      >.list {
        .action {
          display: flex;
          button:first-child {
            flex: 1;
            margin-right: 10px;
          }
        }
        .text {
          p {
            &:first-child {
              font-weight: 700;
            }
            &:last-child {
              margin-top: 5px;
              span {
                font-size: 14px;
                font-weight: 700;
              }
            }
            margin-bottom: 0;
          }
          span {
            font-size: 25px;
            line-height: 1.3;
          }
        }
      }
      .tips {
        text-align: center;
        color: #b7b7b7;
        margin-bottom: 10px;
        margin-top: 5px;
      }
    }
    >.right {
      border: 1px solid #eee;
      width: 280px;
      flex-shrink: 1;
      background-color: white;
      border-radius: 5px;
      margin-bottom: 15px;
      padding-bottom: 5px;
      >.title {
        margin: 20px 0;
        font-size: 18px;
        text-align: center;
      }
      .service {
        width: 80%;
        margin: 0 auto;
        .item {
          display: flex;
          align-items: center;
          img {
            width: 50px;
          }
          .text {
            width: calc(100% - 65px);
            margin-left: auto;
            .title {
              font-size: 16px;
            }
            .subtitle {
              font-size: 14px;
              color: #898989;
            }
          }
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 950px){
    .form-line-box {
      flex-direction: column;
      .left {
        .line {
          flex-direction: column;
          .ant-form-item {
            width: auto!important;
            margin-right: 0!important;
            .ant-form-item-label {
              width: 100px;
            }
          }
        }
      }
      >button {
        margin-left: 0;
      }
    }
    .content {
      margin-bottom: 20px;
      >.left {
        margin-right: 0;
        .list {
          .component-hotel {
            flex-direction: column;
            .left {
              object-fit: cover;
            }
            .center {
              flex: 1;
              margin: 0 10px;
            }
            .right {
              margin-top: 5px;
              padding: 0 15px;
              margin-right: 0;
              width: 100%;
              box-sizing: border-box;
              align-items: center;
              display: flex;
              flex-direction: row;
              .text {
                flex: 1;
                p {
                  span {
                    font-size: 18px;
                  }
                }
                p:last-child {
                  display: none;
                }
              }
              .action {
                button {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
      >.right {
        display: none;
      }
    }
  }
}

.buy-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  .info {
    padding: 0 25px;
    .line {
      display: flex;
      .ant-form-item:first-child {
        margin-right: 15px;
      }
    }
  }
  .price {
    width: 100%;
    padding: 0 25px;
    p {
      line-height: 1.6;
      font-size: 16px;
      span{
        &:nth-child(1) {
          margin-right: 10px;
        }
        &:nth-child(2) {
          font-weight: 700;
        }
      }
    }
  }
}