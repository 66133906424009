$color: #3964f9;
@import '~antd/dist/antd.css';
.width {
  width: 95%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.z-width {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}
* {
  text-decoration: none;
  padding: 0;
  margin: 0;
}
a {
  color: #333;
}
body {
  background-color: #fafafa;
}
