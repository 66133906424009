$color: #3964f9;
#header {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 25px;
  text-shadow: 1px 1px 4px rgba(0,0,0,.5);
  &.position {
    margin-top: 0;
    position: absolute;
    top: 25px;
    left: 0;
  }
  &.white {
    .center {
      .logo {
        img.color {
          display: none;
        }
        img.white {
          display: block;
        }
      }
      .menus {
        a {
          color: #fff;
        }
      }
      .users {
        .login {
          .login-btn {
            background-color: white;
            color: $color;
          }
          .register-btn {
            color: white;
          }
        }
        .info {
          color: white;
        }
      }
    }
  }
  .center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    .logo {
      position: absolute;
      left: 0;
      top: 0;
      max-width: 200px;
      max-height: 65px;
      width: 200px;
      height: 65px;
      img {
        display: block;
        &.white {
          display: none;
        }
      }
    }
    .right {
      height: 65px;
      display: flex;
      align-items: center;
    }
    .mask, .menu-icon {
      display: none;
    }
    .users {
      position: absolute;
      right: 0;
      .login {
        display: flex;
        >* {
          height: 35px;
          line-height: 35px;
          font-weight: 700;
          border-radius: 5px;
          padding: 0 35px;
          cursor: pointer;
        }
        .login-btn {
          margin-right: 15px;
          background-color: $color;
          color: white;
        }
        .register-btn {
          color: $color;
        }
      }
      .info {
        color: $color;
      }
    }
    .menus {
      display: flex;
      a {
        color: #333;
        font-size: 18px;
        font-weight: 400;
        &:hover {
          opacity: .8;
        }
      }
      a:not(:last-child) {
        margin-right: 25px;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .center {
      .logo {
        max-width: 150px;
        max-height: 45px;
        position: relative;
      }
      .right {
        z-index: 10;
        position: fixed;
        background: white;
        height: 100%;
        left: -100%;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        &.active {
          left: 0;
        }
        .menus {
          flex-direction: column;
          a {
            color: black;
            margin-right: 0;
            text-align: center;
            margin-bottom: 20px;
            &.active {
              color: $color;
              font-weight: 700;
            }
          }
        }
        .users {
          .login {
            flex-direction: column;
            a {
              margin-right: 0;
              text-align: center;
              &.login-btn {
                margin-top: 10px;
                box-shadow: 1px 1px 3px #3964f9;
              }
              &.register-btn {
                background: #3964f9;
                color: white;
                margin-top: 15px;
              }
            }
          }
          .info {
            margin-top: 20px;
            display: block;
            color: white;
            padding: 5px 10px;
            border-radius: 8px;
            background: $color;
            font-size: 18px;
            box-shadow: 1px 1px 5px rgba($color, .5);
          }
          position: relative;
        }
      }
      .mask {
        position: fixed;
        inset: 0;
        background: black;
        opacity: .6;
        z-index: 1;
        &.active {
          display: block;
        }
      }
      .menu-icon {
        background: url("./menu.png") no-repeat no-repeat;
        background-size: 100% 100%;
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        z-index: 0;
      }
    }
  }
}