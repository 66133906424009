$color: #3964f9;
#user #order-list {
  h1 {
    margin-bottom: 0;
  }
  p {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    .item {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 1px 3px rgba(0,0,0,.1);
      display: flex;
      padding: 10px 15px;
      border: 1px solid #eee;
      .hotel-img {
        border-radius: 5px;
        width: 150px;
        height: 120px;
        object-fit: cover;
        margin-right: 15px;
      }
      .hotel-info {
        display: flex;
        flex-direction: column;
        .name {
          margin-top: 3px;
          font-weight: 700;
          font-size: 18px;
        }
        .position {
          color: #555555;
          margin-top: 5px;
        }
        .brand {
          margin-top: auto;
          margin-bottom: 6px;
          color: #555555;
        }
      }
      .right {
        margin-left: auto;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .price {
          margin-top: 5px;
          color: green;
          font-weight: 700;
        }
        .status {
          font-weight: 700;
          margin-top: 5px;
          &-0 {
            color: #ff8000;
          }
          &-1 {
            color: $color;
          }
        }
      }
    }
  }
  @media screen and (max-width: 950px){
    .list {
      width: 100%;
      .item {
        width: 100%;
        flex-wrap: wrap;
        .hotel-img {
          width: 120px;
          height: 100px;
          object-fit: cover;
        }
        .hotel-info {
          width: calc(100% - 120px - 15px);
          overflow: hidden;
        }
        .right {
          width: 100%;
          margin: 10px 0 0 0;
          flex-direction: row;
          align-items: center;
          padding: 0;
          .date {
            margin-right: auto;
          }
          .price, .status {
            margin-top: 0;
          }
          .status {
            margin-left: 5px;
          }
        }
      }
    }
  }
}