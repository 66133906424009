$color: #3964f9;
.component-pay {
  .pay_type {
    >p {
      font-size: 16px;
      span {
        font-weight: 700;
        color: $color;
        font-size: 20px;
        margin-left: 5px;
      }
    }
    .pay_list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      .pay_item {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 20px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0,0,0,.05);
          border-radius: 5px;
          transition: .3s;
        }
        &.active {
          background-color: rgba($color, .15);
        }
        img {
          max-width: 50px;
          width: 70%;
        }
        &.wallet .text {
          padding: 8px 0;
          p:first-child {
            line-height: 1.5;
          }
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            text-align: center;
            margin-bottom: 0;
            &:first-child {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .action {
      width: 100%;
      display: flex;
      margin-top: 20px;
      >button:first-child {
        flex: 1;
        margin-right: 10px;
      }
    }
  }
  .pay_load {
    p {
      font-size: 18px;
      font-weight: 700;
      color: #333;
      margin-bottom: 25px;
    }
    button {
      width: 100%;
    }
  }
  .pay_result {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    width: 300px;
    margin: 0 auto;
    img {
      width: 60px;
      margin-bottom: 20px;
    }
    p {
      font-weight: 700;
      font-size: 18px;
      text-align: center;
    }
    .action {
    }
  }
}