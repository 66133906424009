$color: #3964f9;
#home {
  >.banner {
    height: 650px;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    .search-container {
      display: flex;
      margin-top: 15px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .search-box {
        background-color: white;
        border-radius: 10px;
        padding: 25px 35px;
        h1 {
          font-size: 18px;
          margin-bottom: 25px;
          font-weight: 700;
        }
      }
      .title {
        font-weight: 700;
        font-size: 60px;
        color: white;
        margin-bottom: 35px;
        text-shadow: -1px 1px 20px black;
      }
      .search-border {
        background-color: rgba(0,0,0,.2);
        border-radius: 10px;
        padding: 5px;
      }
      .search {
        width: 900px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        border: 5px solid white;
        height: 50px;
        align-items: center;
        position: relative;
        .search-list {
          position: absolute;
          left: 0;
          width: 80%;
          background: white;
          top: 100%;
          box-shadow: 1px 1px 5px rgba(0,0,0,.16);
          border-radius: 10px;
          overflow: hidden;
          .search-item {
            height: 40px;
            line-height: 40px;
            padding-left: 10px;
            cursor: pointer;
            &:hover {
              font-weight: 700;
              background: rgba(0,0,0,.1);
            }
          }
        }
        .date {
          color: #828282;
          font-size: 14px;
          margin-right: 15px;
          cursor: pointer;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            margin-bottom: 0;
            font-size: 12px;
            span {
              display: inline-block;
              text-align: right;
              width: 25px;
              margin-right: 2px;
            }
          }
        }
        >input {
          border-radius: 10px;
          text-align: center;
          margin-right: 10px;
          padding: 0 20px 0 50px;
          font-size: 18px;
          flex: 1;
          border: none;
          outline: none;
          &::placeholder {
            color: rgba(0,0,0,.2);
            font-weight: 700;
            font-size: 14px;
          }
        }
        button {
          margin-left: auto;
          height: 100%;
          border: none;
          background-color: $color;
          color: white;
          font-size: 23px;
          padding: 0 20px;
          border-radius: 10px 10px 10px 10px;
          cursor: pointer;
        }
      }
    }
  }
  .box1 {
    background-color: white;
    .z-width {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 55px;
      padding: 50px 0;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(0,0,0,0);
        padding: 20px 0;
        transition: .3s;
        &:hover {
          box-shadow: 2px 2px 8px rgba(0,0,0,.2);
          transform: translateY(-5px);
          border: 1px solid $color;
          border-radius: 5px;
        }
        img {
          height: 60px;
        }
        .text {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          .title {
            margin: 10px 0;
            font-size: 25px;
          }
          .subtitle {
            color: rgba(0,0,0,.5);
          }
        }
      }
    }
  }
  .box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    position: relative;
    >* {
      position: relative;
      z-index: 1;
    }
    >.background, >.background .shadow, >.background .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      &.shadow {
        z-index: 2;
        backdrop-filter: blur(3px);
        background-color: rgba(0,0,0,.2);
      }
      &.img {
        z-index: 1;
        background: url("./hotel2.png") no-repeat center bottom;
      }
    }
    >.title {
      font-size: 36px;
      color: white;
      font-weight: 700;
      margin-bottom: 10px;
      text-shadow: 1px 1px 3px rgba(0,0,0,.5);
    }
    >.subtitle {
      color: rgba(255,255,255,.7);
      font-size: 18px;
      margin-bottom: 40px;
    }
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      .item {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        background-color: white;
        cursor: pointer;
        transition: .3s all;
        &:hover {
          transform: translateY(-10px);
        }
        img {
          width: 200px;
          height: 100%;
          min-height: 160px;
          left: 0;
          top: 0;
          z-index: 0;
          transition: .4s;
        }
        .right {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px 20px;
          .more {
            transition: .4s;
            font-size: 14px;
            background-color: $color;
            padding: 1px 10px;
            border-radius: 5px;
            color: white;
          }
          .title {
            font-size: 16px;
            line-height: 1.5;
            transition: .4s;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .position {
            font-size: 12px;
            line-height: 1.3;
            margin-bottom: 5px;
            color: rgba(0,0,0,.6);
          }
          .price {
            line-height: 1;
            font-weight: 700;
            font-size: 20px;
            margin-top: auto;
            margin-bottom: 10px;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .box3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    background-color: white;
    text-align: center;
    >.title {
      font-size: 36px;
      margin-bottom: 10px;
    }
    >.subtitle {
      color: #888;
      font-size: 18px;
      margin-bottom: 40px;
    }
    .list {
      display: grid;
      grid-gap: 45px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(2, 80px);
      .item {
        position: relative;
        overflow: hidden;
        &:hover {
          img{
            position: relative;
            top: -100%;
          }
        }
        img {
          height: 200%;
        }
      }
    }
  }
  @media screen and (max-width: 950px){
    .banner {
      .search-container {
        .title {
          font-size: 12vw;
        }
        .search-border {
          width: 100%;
          .search {
            width: 100%;
            input {
              padding: 0;
              text-align: center;
            }
            .submit {
              padding: 0 10px;
              font-size: 16px;
            }
          }
          .date {
            display: none;
          }
        }
      }
    }
    .box1 {
      .z-width {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .box2 {
      .list {
        grid-template-columns: 1fr;
        .item {
          img {
            object-fit: cover;
            width: 40%;
            min-height: 130px;
          }
          .right {
            flex: 1;
          }
        }
      }
    }
    .box3 {
      .list {
        grid-template-columns: repeat(2, 1fr);
        .item {
          height: 80px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.range-form {
  display: none;
}
@media screen and (max-width: 950px){
  .range-form {
    display: inline-flex;
  }
  .range-box {
    display: none;
  }

  .ant-picker-time-panel {
    display: none;
  }
}