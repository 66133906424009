$color: #3964f9;
#contact {
  .email {
    display: flex;
    margin: 0 auto;
    width: 1400px;
    flex-shrink: 1;
    align-items: flex-start;
    >.left {
      width: 842px;
      background-color: white;
      border-radius: 15px;
      box-shadow: 0 30px 62px rgba(0,0,0,.16);
      position: relative;
      margin-top: -75px;
      padding: 50px 30px;
      .title {
        font-size: 27px;
        color: black;
        line-height: 1;
        margin-bottom: 25px;
      }
      .flex {
        display: flex;
      }
      .ant-row.ant-form-item {
        padding-top: 15px;
        //overflow: hidden;
        .ant-form-item-label {
          //height: auto;
          width: 90px;
          label {
            font-size: 15px;
          }
        }
      }
      .center {
        text-align: center;
        padding-top: 15px;
        button {
          height: 55px;
          padding: 0 55px;
          background-color: $color;
          color: white;
          border: none;
          border-radius: 5px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
    >.right {
      flex: 1;
      padding-left: 25px;
      .top {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          align-items: flex-start;
          margin-top: 45px;
          &:first-child {
            margin-top: 20px;
          }
          .left {
            width: 50px;
            text-align: center;
            flex-shrink: 1;
            img {
              height: 35px;
              width: auto;
            }
          }
          .text {
            flex: 1;
            font-size: 22px;
            font-weight: 400;
            line-height: 1.364;
          }
        }
      }
      .faq {
        background-color: $color;
        color: white;
        padding: 25px 40px 20px 40px;
        border-radius: 5px;
        margin-top: 40px;
        .title {
          font-size: 35px;
        }
        .content {
          margin-top: 15px;
          font-size: 18px;
        }
        button {
          margin-top: 20px;
          padding: 10px 35px;
          color: white;
          border-radius: 5px;
          border: none;
          background-color: #28cc8b;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
  .box1 {
    background: url("./img/boomerang-reverse.svg") no-repeat -45% 50%;
    background-size: 50%;
    padding: 50px 0;
    >.z-width {
      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
        >.left {
          font-family: "Raleway",Helvetica,Arial,sans-serif;
          font-weight: 700;
          line-height: 1.4;
          font-size: 48px;
          color: $color;
          span {
            color: #5f931b;
          }
        }
        >.right {
          color: $color;
          width: 40%;
          font-size: 21px;
        }
      }
      .content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 25px;
        .item {
          display: flex;
          padding: 20px;
          flex-direction: column;
          align-items: center;
          box-shadow: 2px 2px 3px rgba(55,95,174,.1);
          background: white;
          >img {
            height: 75px;
            margin-bottom: 15px;
          }
          .title {
            color: #36c;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;
          }
          .text {
            text-align: center;
          }
        }
      }
    }
  }
  .box2 {
    color: white;
    padding: 30px 0;
    background: $color;
    .z-width {
      .title {
        margin-bottom: 25px;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
        span {
          color: #a8d356;
        }
      }
      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: 30px;
        }
      }
    }
  }
  .bottom-info {
    width: 100%;
    padding: 40px 0;
    background-image: url("~@/Components/Banner/image.jpg");
    background-repeat: no-repeat no-repeat;
    background-size: cover;
    margin-top: 45px;
    .box {
      width: 900px;
      margin: 0 auto;
      background: white;
      border-radius: 5px;
      padding: 20px 15px;
      text-align: center;
      box-shadow: 0px 30px 62px 0px rgba(0,0,0,.14);
      .title {
        font-weight: 700;
        font-size: 26px;
        color: $color;
        margin-bottom: 15px;
      }
      .content {
        color: rgba(0,0,0,.7);
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 950px) {
    .email {
      width: 95%;
      flex-direction: column;
      .left {
        width: 100%;
      }
      .right {
        margin-top: 25px;
      }
    }
    .bottom-info {
      .box {
        width: 80%;
      }
    }
    .box1 {
      .z-width {
        .top {
          .right {
            display: none;
          }
        }
        .content {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 30px
        }
      }
    }
    .box2 {
      .z-width{
        .list {
          flex-wrap: wrap;
          justify-content: center;
          img {
            margin-right: 30px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}