$color: #3964f9;
.values-modal {
  .values-box {
    height: 500px;
    max-height: 70vh;
    background-color: rgba(0,0,0,.1);
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .item {
      max-width: 90%;
      min-width: 30px;
      background-color: $color;
      color: white;
      margin-bottom: 15px;
      border-radius: 5px;
      padding: 5px 10px;
      position: relative;
      white-space: pre-line;
      &::before {
        content: " ";
        position: absolute;
        border: 10px solid transparent;
        border-right-color: $color;
        top: 3px;
        left: -18px;
        width: 0;
        height: 0;
      }
      .box {

      }
      &.user {
        margin-left: auto;
        background-color: white;
        color: black;
        &::before {
          border-right-color: transparent;
          border-left-color: white;
          left: auto;
          right: -18px;
        }
      }
    }
  }
}