$color: #3964f9;
#login {
  >.banner {
    height: 900px;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    .zz {
      background-color: rgba(0,0,0,.5);
    }
    .login-box {
      $width: 150px;
      margin-top: 55px;
      padding: $width / 3 30px 20px;
      background-color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 380px;
      border-radius: 10px;
      .avatar {
        position: absolute;
        height: $width;
        width: $width;
        top: 0 - $width + $width / 3;
        border: 4px solid white;
        border-radius: 50%;
      }
      .title {
        padding: 15px 0;
        font-size: 18px;
        font-weight: 700;
      }
      .children {
        width: 100%;
      }
      .line {
        text-align: center;
        margin-top: 18px;
        a {
          color: $color;
        }
      }
      .reg-form {
        .ant-form-item-label {
          width: 80px;
        }
      }
      .form-line {
        display: flex;
        .ant-form-item {
          flex: 1;
          margin-right: 10px;
        }
        button {
          margin-top: auto;
          margin-bottom: 24px;
        }
      }
    }
  }
}