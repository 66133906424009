$color: #3964f9;
.component-banner {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  position: relative;
  z-index: 0;
  .zz {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-color: $color;
    z-index: 0;
    opacity: .7;
  }
  >* {
    position: relative;
    z-index: 1;
  }
  .title-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    .title {
      font-size: 55px;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 20px;
    }
    .subtitle {
      font-size: 22px;
      font-weight: 300;
      line-height: 1.5;
    }
  }
}