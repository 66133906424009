$color: #3964f9;
#footer {
  border-top: 5px solid $color;
  background-color: #34313b;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  >img {
    margin-top: 10px;
    height: 50px;
    margin-bottom: 20px;
  }
  >div {
    color: white;
    font-size: 14px;
  }
  p {
    margin-bottom: 5px;
    color: white;
  }
}