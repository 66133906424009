$color: #3964f9;
#user {
  .component-banner {
    .title {
      margin-bottom: 50px;
    }
  }
  >.z-width {
    background-color: white;
    position: relative;
    top: -80px;
    border-radius: 5px;
    padding: 35px 0;
    box-shadow: 0 1px 3px rgba(0,0,0,.16);
    .flex {
      display: flex;
    }
    .user-info {
      width: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #eee;
      flex-shrink: 0;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .name {
        font-weight: 700;
        margin-top: 10px;
        font-size: 16px;
      }
      .email {
        color: #555555;
      }
      .balance {
        margin-top: 5px;
        span {
          color: $color;
          font-weight: 700;
        }
      }
      button {
        margin-top: 10px;
      }
    }
    .right {
      padding: 0 20px;
      width: calc(100% - 280px);
      overflow: hidden;
      .links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 20px;
        .item {
          padding: 20px 0;
          border: 1px solid rgba(0,0,0,.15);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #1296db;
          font-weight: 700;
          font-size: 18px;
          cursor: pointer;
          &:hover {
            border-color: #1296db;
          }
          img {
            margin-right: 15px;
            width: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 950px){
    .z-width {
      padding-top: 20px;
      .flex {
        flex-direction: column;
        >div {
          width: 100%;
        }
        .right {
          margin-top: 20px;
          .links {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}