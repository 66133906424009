$color: #3964f9;
.component-hotel {
  display: flex;
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  .f-l {
    display: flex;
    flex: 1;
  }
  .left {
    height: 110px;
    border-radius: 5px;
    width: 110px;
    margin-left: 15px;
  }
  .center {
    margin-left: 30px;
    width: calc(100% - 165px - 230px);
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      color: #555;
      margin-bottom: 5px;
    }
    .position {
      font-size: 14px;
      color: #999;
    }
    .brand {
      margin-top: auto;
      line-height: 30px;
      color: #999;
      font-size: 12px;
    }
  }
  .right {
    display: flex;
    width: 200px;
    flex-direction: column;
    .action {
      margin-top: auto;
    }
  }
}